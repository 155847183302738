import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    Tooltip,
    Autocomplete,
    Paper,
    ToggleButtonGroup,
    ToggleButton,
    Fab,
} from '@mui/material';
import {
    faEnvelope,
    faFilePdf,
    faFloppyDisk,
    faSave,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ContractDoc,
    ContractStatus,
    ProtocolTypes,
} from '../../types/contract';
import { AppContext } from '../../hooks/context';
import { useNavigate, useParams } from 'react-router-dom';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { ContactPopup, Navbar } from '../../components';
import {
    loadTitle,
    setDocumentTitle,
    useURLQuery,
} from '../../utils/misc-utils';
import { useQuery /*, useQueryClient */, useQueryClient } from 'react-query';
import { AdminApi, ContractApi } from '../../api';
import { Field, FieldNames, FieldType } from '../../types/edit-contract';
import {
    LandlineLeftInputFields,
    LandlineRightInputFields,
} from '../../assets/edit-contract-templates/landline-template';
import {
    MobileLeftInputFields,
    MobileRightInputFields,
} from '../../assets/edit-contract-templates/mobile-template';
import {
    CongstarLeftInputFields,
    CongstarRightInputFields,
} from '../../assets/edit-contract-templates/congstar-template';
import DeleteContractDialog from '../../components/delete-contract-dialog/delete-contract-dialog';
import { MessageLeft, TextInput } from '../../components/messages/messages';
import useClasses from '../../components/messages/useClasses';
import moment from 'moment';

const leftBottomInputFields: Field[] = [
    // {
    //     key: 'inquiry',
    //     label: 'Rückfrage',
    //     type: FieldType.TEXT,
    //     onlyIfStatus: ['INQUIRY', 'INQUIRY_COMPLETED'],
    // },
];

const rightBottomInputFields: Field[] = [
    // {
    //     key: 'inquiryAnswer',
    //     label: 'Antwort Rückfrage',
    //     type: FieldType.TEXT,
    //     onlyIfStatus: ['INQUIRY', 'INQUIRY_COMPLETED'],
    //     nonAdminEditable: true,
    // },
];

const centerBottomInputFields: Field[] = [
    {
        key: 'comment',
        label: 'Kommentar',
        type: FieldType.TEXT,
        multiline: true,
    },
];

const styles = () => ({
    paper: {
        width: '80vw',
        height: '30vh',
        maxWidth: '500px',
        maxHeight: '700px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    paper2: {
        width: '80vw',
        maxWidth: '500px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
    },
    messagesBody: {
        width: 'calc( 100% - 20px )',
        margin: 10,
        overflowY: 'scroll',
        height: 'calc( 100% - 80px )',
    },
});

const EditContract: FC = () => {
    const { contractId, contractType } = useParams();
    const context = useContext(AppContext);
    const [contract, setContract] = useState<ContractDoc>();
    const [changes, setChanges] = useState<Partial<ContractDoc>>({});
    const [loading, setLoading] = useState(false);
    const [adminOrInquiry, setAdminOrInquiry] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [originalEmail, setOriginalEmail] = useState('');
    const urlQuery = useURLQuery();
    const queryStatusFilter = urlQuery.get('status');
    const queryRegionFilter = urlQuery.get('region');
    const queryClient = useQueryClient();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const [leftFields, setLeftFields] = useState<(ReactElement | null)[]>([]);
    const [rightFields, setRightFields] = useState<(ReactElement | null)[]>([]);
    const [leftBottomFields, setLeftBottomFields] = useState<
        (ReactElement | null)[]
    >([]);
    const [rightBottomFields, setRightBottomFields] = useState<
        (ReactElement | null)[]
    >([]);
    const [centerBottomFields, setCenterBottomFields] = useState<
        (ReactElement | null)[]
    >([]);

    const [showActivatedOptions, setShowActivatedOptions] = useState(false);

    const navigate = useNavigate();

    const { error: usersError, data: usersData } = useQuery({
        queryKey: ['getUsers'],
        queryFn: () => {
            return AdminApi.getUsers(context.authToken, {
                noPage: true,
                minimal: true,
            });
        },
        enabled: context.authToken !== undefined && context.isSuperadmin,
    });

    const { isLoading, error, data } = useQuery({
        queryKey: ['getContract', contractId],
        queryFn: () => {
            if (!contractId) return;
            return ContractApi.getContract(context.authToken, contractId);
        },
        enabled: contractId !== '',
    });

    useEffect(() => {
        if (usersData && usersData.data && contract) {
            renderFields(contract);
        }
    }, [usersData, contract]);

    useEffect(() => {
        setDocumentTitle('Vertrag bearbeiten');
        return () => loadTitle();
    }, []);

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data && data.data.contract) {
            setContract(data.data.contract);
            setOriginalEmail(data.data.contract?.email);
            const r = () => {
                renderFields(data.data.contract);
            };
            r();
            if (context.isAdmin || data.data.contract.status === 'INQUIRY') {
                setAdminOrInquiry(true);
            }
        }
    }, [error, data, context.isAdmin]);

    const handleChange = (c: ContractDoc, key: string, value: string = '') => {
        if (c) {
            const change = {
                [key]: value,
            };
            const newContract = Object.assign(c, change);
            setContract(newContract);
            if (key !== 'creator' && key !== 'creatorDisplayName') {
                setChanges(Object.assign(changes, change));
            }
            renderFields(newContract);
        }
    };

    const renderFields = (c: ContractDoc) => {
        let left: Field[];
        let right: Field[];
        switch (c.type) {
            case 'MOBILE':
                left = MobileLeftInputFields;
                right = MobileRightInputFields;
                break;
            case 'CONGSTAR':
                left = CongstarLeftInputFields;
                right = CongstarRightInputFields;
                break;
            case 'LANDLINE':
            default:
                left = LandlineLeftInputFields;
                right = LandlineRightInputFields;
                break;
        }
        const filteredLeft = left.filter((field) =>
            !context.isAdmin && field.hideNonAdmin ? false : true,
        );
        const filteredRight = right.filter((field) =>
            !context.isAdmin && field.hideNonAdmin ? false : true,
        );
        const filteredLeftBottom = leftBottomInputFields.filter((field) =>
            (!context.isAdmin && field.hideNonAdmin) ||
            (field.onlyIfStatus &&
                !field.onlyIfStatus.includes(c.status) &&
                !c[field.key])
                ? false
                : true,
        );
        const filteredRightBottom = rightBottomInputFields.filter((field) =>
            (!context.isAdmin && field.hideNonAdmin) ||
            (field.onlyIfStatus &&
                !field.onlyIfStatus.includes(c.status) &&
                !c[field.key])
                ? false
                : true,
        );
        const filteredCenterBottom = centerBottomInputFields.filter((field) =>
            (!context.isAdmin && field.hideNonAdmin) ||
            (field.onlyIfStatus &&
                !field.onlyIfStatus.includes(c.status) &&
                !c[field.key])
                ? false
                : true,
        );
        setLeftFields(filteredLeft.map((f) => renderField(f, c)));
        setRightFields(filteredRight.map((f) => renderField(f, c)));
        setLeftBottomFields(filteredLeftBottom.map((f) => renderField(f, c)));
        setRightBottomFields(filteredRightBottom.map((f) => renderField(f, c)));
        setCenterBottomFields(
            filteredCenterBottom.map((f) => renderField(f, c)),
        );
    };

    const renderField = (field: Field, c: ContractDoc) => {
        if (field.hideIfUndefined && !c[field.key]) return null;
        if (
            field.hideNonRegionLeadAndAdmin &&
            context.role !== 'REGIONLEAD' &&
            !context.isAdmin
        )
            return null;
        if (
            field.onlyIfStatus &&
            !field.onlyIfStatus.includes(c.status) &&
            !c[field.key]
        )
            return null;
        let value = c[field.key] || '';
        let disabled = field.notEditable || !context.isSuperadmin;
        if (
            field.nonAdminEditable &&
            field.onlyIfStatus &&
            field.onlyIfStatus.includes(c.status)
        ) {
            disabled = false;
        }
        if (field.nonAdminEditable) {
            disabled = false;
        }
        if (
            field.editableIfRemoteValStartsWith &&
            (value.startsWith(field.emptyIfRemoteValStartsWith) ||
                changes.contractNumber)
        ) {
            if (!field.nonAdminEditable && context.isAdmin) {
                disabled = false;
            } else if (field.nonAdminEditable) {
                disabled = false;
            }
        }
        if (
            field.emptyIfRemoteValStartsWith &&
            data.data.contract[field.key].startsWith(
                field.emptyIfRemoteValStartsWith,
            )
        ) {
            value = '';
        }
        return (
            <FormControl
                key={field.key}
                color="secondary"
                sx={{
                    flex: 1,
                    mt: 2,
                    mb: 2,
                    width: { xs: '18rem', md: '20rem', lg: '30rem' },
                    justifyContent: 'center',
                    alignSelf: 'left',
                    alignItems: 'left',
                }}
            >
                <Typography>{field.label}</Typography>
                {field.type === FieldType.USER_SELECT &&
                    (context.isSuperadmin ? (
                        <Autocomplete
                            disablePortal
                            options={usersData?.data?.users || []}
                            value={{
                                id: c.creatorId,
                                username: c.creator,
                                displayName: c.creatorDisplayName,
                            }}
                            getOptionLabel={(option: any) =>
                                option.displayName || option.username
                            }
                            onChange={(e, value: any) => {
                                if (value) {
                                    handleChange(c, 'creator', value?.username);
                                    handleChange(c, 'creatorId', value?.id);
                                    handleChange(
                                        c,
                                        'creatorDisplayName',
                                        value?.displayName,
                                    );
                                }
                            }}
                            noOptionsText={
                                usersError
                                    ? 'Fehler beim Laden der Benutzer'
                                    : 'Keine Benutzer'
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                />
                            )}
                            clearIcon={false}
                        />
                    ) : (
                        <TextField
                            disabled={disabled}
                            multiline={field.multiline}
                            value={value}
                        />
                    ))}
                {field.type === FieldType.TEXT && (
                    <TextField
                        disabled={disabled}
                        multiline={field.multiline}
                        value={
                            field.valueFormatter
                                ? field.valueFormatter(value)
                                : value
                        }
                        onChange={(e) =>
                            handleChange(c, field.key, e.currentTarget.value)
                        }
                        InputProps={Object.assign(
                            {
                                endAdornment:
                                    field.key === 'customerContractEmailSent' &&
                                    (!value ||
                                        (changes.email &&
                                            changes.email !==
                                                originalEmail)) ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                id="sendEmailBtn"
                                                onClick={() =>
                                                    sendContractEmail()
                                                }
                                            >
                                                <Tooltip title="Vertrag senden">
                                                    <FontAwesomeIcon
                                                        icon={faEnvelope}
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        </InputAdornment>
                                    ) : undefined,
                            },
                            field.multiline && { style: { height: 100 } },
                        )}
                    />
                )}
                {field.type === FieldType.SELECT && (
                    <>
                        {context.isAdmin && field.key === 'status' && (
                            <Box display="flex" flexDirection="row">
                                <Box display="flex" flexDirection="column">
                                    <ToggleButtonGroup
                                        value={value}
                                        exclusive
                                        onChange={(e, v) =>
                                            handleChange(c, field.key, v)
                                        }
                                        sx={{ pb: 1 }}
                                    >
                                        <ToggleButton
                                            value="ACTIVATED_NEW"
                                            color="success"
                                        >
                                            <Typography>Neuvertrag</Typography>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="ACTIVATED_VVL"
                                            color="success"
                                        >
                                            <Typography>VVL</Typography>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="ACTIVATED_PORTING"
                                            color="success"
                                        >
                                            <Typography>Portierung</Typography>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="ACTIVATED_DT"
                                            color="success"
                                        >
                                            <Typography>DT ohne EG</Typography>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="ACTIVATED_DT_NEW"
                                            color="success"
                                        >
                                            <Typography>DT mit EG</Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                    <ToggleButtonGroup
                                        value={
                                            showActivatedOptions
                                                ? 'ACTIVATED'
                                                : value
                                        }
                                        exclusive
                                        onChange={(e, v) => {
                                            if (v === 'ACTIVATED') {
                                                console.log('Here');
                                                setShowActivatedOptions(true);
                                            } else {
                                                handleChange(c, field.key, v);
                                                setShowActivatedOptions(false);
                                            }
                                        }}
                                        sx={{ pb: 1 }}
                                    >
                                        <ToggleButton
                                            value="DENIED"
                                            color="error"
                                        >
                                            <Typography>Abgelehnt</Typography>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="DENIED_CONTRACT_CHANGE"
                                            color="error"
                                        >
                                            <Typography>
                                                Abgelehnt Tarifwechsel
                                            </Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Fab
                                    size="small"
                                    color="primary"
                                    onClick={saveAndLoadNextContract}
                                    disabled={loading}
                                    sx={{ mt: 0.5, ml: 1 }}
                                >
                                    <Tooltip title="Speichern und nächsten Vertrag laden">
                                        <FontAwesomeIcon icon={faSave} />
                                    </Tooltip>
                                </Fab>
                            </Box>
                        )}
                        <Select
                            value={value}
                            disabled={disabled}
                            onChange={(e) =>
                                handleChange(c, field.key, e.target.value)
                            }
                        >
                            {getMenuItems(field, value)}
                        </Select>
                    </>
                )}
            </FormControl>
        );
    };

    const getMenuItems = (field: Field, value?: string) => {
        if (field.valueType) {
            return Object.keys(field.valueType).map((v) => {
                const isActivatedStatus =
                    field.key === 'status' && v === 'ACTIVATED';
                if (isActivatedStatus && value !== 'ACTIVATED') return null;
                return (
                    <MenuItem key={v} value={v} disabled={isActivatedStatus}>
                        {field.valueType ? field.valueType[v] : v}
                    </MenuItem>
                );
            });
        } else if (field.values) {
            return field.values.map((v) => (
                <MenuItem key={v} value={v}>
                    {v}
                </MenuItem>
            ));
        }
        return null;
    };

    const sendContractEmail = async () => {
        try {
            if (contractId) {
                await ContractApi.sendContractEmail(
                    context.authToken,
                    Object.assign(
                        {
                            contractNumber: contractId,
                        },
                        changes.email && { email: changes.email },
                    ),
                );
            }
            queryClient.invalidateQueries('getContract');
        } catch (e) {
            console.error(e);
        }
    };

    const sendInquiryEmail = async (message: string) => {
        try {
            if (contractId) {
                await ContractApi.sendContractInquiryEmail(
                    context.authToken,
                    contractId,
                    Object.assign({
                        message,
                    }),
                );
                setOpenPopup(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const saveAndLoadNextContract = async () => {
        const saved = await onSave(true);
        if (saved) {
            const r = await ContractApi.getNextNewContract(
                context.authToken,
                queryRegionFilter,
            );
            if (r.data) {
                navigate(
                    `/contract/${r.data}${
                        contractType ? `/${contractType}` : ''
                    }${
                        queryStatusFilter ? `?status=${queryStatusFilter}` : ''
                    }${queryStatusFilter ? '&' : queryRegionFilter ? '?' : ''}${
                        queryRegionFilter ? `region=${queryRegionFilter}` : ''
                    }`,
                );
            }
        }
    };

    const onSave = async (next?: boolean) => {
        setLoading(true);
        let success = false;
        if (Object.keys(changes).length > 0) {
            if (contractId) {
                try {
                    const r = await ContractApi.editContract(
                        context.authToken,
                        contractId,
                        changes,
                    );
                    if (r && r.message) {
                        if (changes.contractNumber) {
                            const cn = r.data.contractNumber;
                            navigate(
                                `/contract/${cn}${
                                    contractType ? `/${contractType}` : ''
                                }${
                                    queryStatusFilter
                                        ? `?status=${queryStatusFilter}`
                                        : ''
                                }${
                                    queryStatusFilter
                                        ? '&'
                                        : queryRegionFilter
                                        ? '?'
                                        : ''
                                }${
                                    queryRegionFilter
                                        ? `region=${queryRegionFilter}`
                                        : ''
                                }`,
                            );
                        } else {
                            queryClient.invalidateQueries('getContract');
                        }
                        setChanges({});
                        success = true;
                    }
                } catch (e) {}
            }
        } else {
            success = true;
            if (!next)
                snackbarUtils.success(`Es wurden keine Änderungen vorgenommen`);
        }

        setLoading(false);

        return success;

        // if (success) {
        //     goToContracts();
        // }
    };

    const goToContracts = () => {
        navigate(
            `/contracts${contractType ? `/${contractType}` : ''}${
                queryStatusFilter ? `?status=${queryStatusFilter}` : ''
            }${queryStatusFilter ? '&' : queryRegionFilter ? '?' : ''}${
                queryRegionFilter ? `region=${queryRegionFilter}` : ''
            }`,
        );
    };

    const openContractPdf = async () => {
        if (contract?.contractUrl) {
            window.open(contract?.contractUrl, '_blank');
        }
    };

    const classes = useClasses(styles);

    return (
        <Box>
            <Navbar title="Vertrag bearbeiten" />
            {isLoading ? (
                <Box display="flex" justifyContent="center" sx={{ mt: 10 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div style={{ height: '100%' }}>
                    <Box sx={{ mr: '5%', ml: '5%' }}>
                        <Box
                            display="flex"
                            sx={{
                                alignSelf: 'center',
                                alignItems: 'flex-start',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: 'space-between',
                                width: { xs: '80%', sm: '85%', lg: '70%' },
                                mr: 'auto',
                                ml: 'auto',
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h5">
                                    Kundendaten
                                </Typography>
                                {leftFields}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h5">
                                    Vertragsdaten
                                </Typography>
                                {rightFields}
                                {contract &&
                                    (ContractStatus[contract.status] ===
                                        ContractStatus.INQUIRY ||
                                        ContractStatus[contract.status] ===
                                            ContractStatus.INQUIRY_COMPLETED ||
                                        (context.isAdmin &&
                                            contract.messages &&
                                            contract.messages.length > 0)) && (
                                        <FormControl
                                            color="secondary"
                                            sx={{
                                                flex: 1,
                                                mt: 2,
                                                mb: 2,
                                                width: {
                                                    xs: '18rem',
                                                    md: '20rem',
                                                    lg: '30rem',
                                                },
                                                justifyContent: 'center',
                                                alignSelf: 'left',
                                                alignItems: 'left',
                                            }}
                                        >
                                            <Paper className={classes.paper}>
                                                <Paper
                                                    id="style-1"
                                                    className={
                                                        classes.messagesBody
                                                    }
                                                >
                                                    {contract.messages.map(
                                                        (m: any) => {
                                                            const timestamp =
                                                                moment(
                                                                    m.timestamp,
                                                                ).format(
                                                                    'HH:mm DD.MM.YYYY',
                                                                );
                                                            let displayName =
                                                                m.sender
                                                                    .username;
                                                            if (
                                                                m.sender
                                                                    .firstName &&
                                                                m.sender
                                                                    .lastName
                                                            ) {
                                                                displayName = `${m.sender.firstName} ${m.sender.lastName}`;
                                                            }
                                                            return (
                                                                <MessageLeft
                                                                    key={m.id}
                                                                    message={
                                                                        m.message
                                                                    }
                                                                    displayName={
                                                                        displayName
                                                                    }
                                                                    timestamp={
                                                                        timestamp
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </Paper>
                                                <TextInput
                                                    contractId={contractId}
                                                    onSave={() => {
                                                        if (
                                                            Object.keys(
                                                                changes,
                                                            ).includes('status')
                                                        ) {
                                                            onSave();
                                                        }
                                                    }}
                                                />
                                            </Paper>
                                        </FormControl>
                                    )}
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            sx={{
                                alignSelf: 'center',
                                alignItems: 'flex-end',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: 'space-between',
                                width: { xs: '80%', sm: '85%', lg: '70%' },
                                mr: 'auto',
                                ml: 'auto',
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                {leftBottomFields}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                {rightBottomFields}
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            sx={{
                                alignSelf: 'center',
                                alignItems: 'flex-end',
                                flexDirection: { xs: 'column', sm: 'row' },
                                justifyContent: 'space-between',
                                width: { xs: '80%', sm: '85%', lg: '70%' },
                                mr: 'auto',
                                ml: 'auto',
                            }}
                        >
                            <Box sx={{ width: '100%' }}></Box>
                            <Box display="flex" flexDirection="column"></Box>
                        </Box>
                        <Box
                            display="flex"
                            sx={{
                                alignSelf: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                width: { xs: '80%', sm: '85%', lg: '70%' },
                                mr: 'auto',
                                ml: 'auto',
                            }}
                        >
                            {centerBottomFields}
                        </Box>
                        <Box>
                            {!loading ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ mt: 5, mb: 10, alignItems: 'center' }}
                                >
                                    {adminOrInquiry && (
                                        <Button
                                            color="success"
                                            variant="contained"
                                            className="formBtn"
                                            sx={{
                                                width: '22rem',
                                                height: '2rem',
                                                flex: 1,
                                                display: 'flex',
                                                mb: 2,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            disabled={
                                                Object.keys(changes).length ===
                                                0
                                            }
                                            onClick={() => onSave(false)}
                                            endIcon={
                                                <FontAwesomeIcon
                                                    icon={faFloppyDisk}
                                                />
                                            }
                                        >
                                            Speichern
                                        </Button>
                                    )}
                                    {(adminOrInquiry ||
                                        contract?.contractUrl) && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="formBtn"
                                            sx={{
                                                width: '22rem',
                                                flex: 1,
                                                mb: 2,
                                            }}
                                            onClick={openContractPdf.bind(this)}
                                            endIcon={
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                />
                                            }
                                        >
                                            PDF Anzeigen
                                        </Button>
                                    )}
                                    {context.isSuperadmin && (
                                        <Button
                                            color="error"
                                            variant="contained"
                                            className="formBtn"
                                            sx={{
                                                width: '22rem',
                                                flex: 1,
                                                mb: 2,
                                            }}
                                            onClick={() =>
                                                setDeleteModalOpen(true)
                                            }
                                            endIcon={
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                />
                                            }
                                        >
                                            Vertrag löschen
                                        </Button>
                                    )}
                                    <IconButton
                                        edge="end"
                                        sx={{
                                            position: 'fixed',
                                            top: 100,
                                            right: 50,
                                        }}
                                        onClick={() => setOpenPopup(true)}
                                    >
                                        <Tooltip title="Anmerkung zum Vertrag übermitteln">
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                    <Button
                                        color="error"
                                        variant="contained"
                                        className="formBtn"
                                        sx={{
                                            width: '22rem',
                                            flex: 1,
                                        }}
                                        onClick={goToContracts.bind(this)}
                                    >
                                        {adminOrInquiry
                                            ? 'Abbrechen'
                                            : 'Schliessen'}
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{ mt: 5, mb: 10, alignItems: 'center' }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </div>
            )}
            <Box
                display="flex"
                sx={{
                    alignSelf: 'center',
                    alignItems: 'flex-end',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    width: { xs: '80%', sm: '85%', lg: '70%' },
                    mr: 'auto',
                    ml: 'auto',
                }}
            >
                <Box sx={{ width: '100%' }}>
                    {contract && context.isAdmin && (
                        <Paper
                            sx={{
                                width: '100%',
                                height: 'auto',
                                mb: 10,
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{ textAlign: 'center', pt: 1 }}
                                >
                                    Datalog
                                </Typography>
                                <Box
                                    sx={{
                                        pl: 5,
                                        pr: 5,
                                        pb: 1,
                                        height: 'auto',
                                    }}
                                >
                                    {contract.protocols.length === 0 && (
                                        <p>Noch keine Einträge vorhanden</p>
                                    )}
                                    {contract.protocols.map((p) => {
                                        const timestamp = moment(
                                            p.timestamp,
                                        ).format('HH:mm DD.MM.YYYY');
                                        let displayName = '';
                                        if (p.isSystem) {
                                            displayName = 'SYSTEM';
                                        } else {
                                            displayName = p.user.username;
                                            if (
                                                p.user.firstName &&
                                                p.user.lastName
                                            ) {
                                                displayName = `${p.user.firstName} ${p.user.lastName}`;
                                            }
                                        }
                                        let info = '';
                                        if (p.type === 'DATA_CHANGED') {
                                            info = p.changedFields
                                                .map((f) => FieldNames[f])
                                                .join(', ');
                                        }
                                        if (p.type === 'STATUS_CHANGED') {
                                            info = `${
                                                ContractStatus[p.oldStatus]
                                            } -> ${
                                                ContractStatus[p.newStatus]
                                            }`;
                                        }
                                        return (
                                            <p>
                                                {timestamp} <b>{displayName}</b>{' '}
                                                {ProtocolTypes[p.type]}
                                                {info && <span>: {info}</span>}
                                            </p>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Paper>
                    )}
                </Box>
            </Box>
            {/* {context.isAdmin && (
                <Fab
                    color="primary"
                    sx={{ position: 'fixed', right: 20, top: '50%' }}
                    onClick={saveAndLoadNextContract}
                    disabled={loading}
                >
                    <Tooltip title="Speichern und nächsten Vertrag laden">
                        <FontAwesomeIcon icon={faSave} />
                    </Tooltip>
                </Fab>
            )} */}
            <ContactPopup
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                onSend={sendInquiryEmail}
            />
            <DeleteContractDialog
                open={deleteModalOpen && context.isSuperadmin}
                contractToDelete={contractId}
                onClose={(deleted: boolean) => {
                    setDeleteModalOpen(false);
                    if (deleted) goToContracts();
                }}
            />
        </Box>
    );
};

export default EditContract;
